import React from "react";
import { Helmet } from "react-helmet";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const About = () => {
  let navigate = useNavigate();
  const navigateToHomePage = () => {
    navigate("/");
  };
  return (
    <div className="about">
      <Helmet>
        <title>About Us - Download Movies Online | Netnaija Movies</title>
        <meta
          name="description"
          content="Learn more about Movieloaded, a movie download website that offers a wide selection of movies for users to download and watch at their convenience."
        />
      </Helmet>
      <div className="back">
        <MdArrowBack onClick={navigateToHomePage} />
      </div>
      <br/>
      <br/>
      <h2>About movieloaded.xyz</h2>
      <p>
        Movieloaded is a movie download website that offers a wide selection of
        movies for users to download and watch at their convenience. Our mission
        is to provide a seamless and user-friendly experience for movie lovers
        around the world.
      </p>
      <h3>Contact Us</h3>
      <a href="/contact">Contact Us</a>
      <h3>Back Home</h3>
      <a href="/">Home</a>
    </div>
  );
};

export default About;
