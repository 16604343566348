import React from 'react';
import { Helmet } from 'react-helmet';
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    let navigate = useNavigate();
  const navigateToHomePage = () => {
    navigate("/");
  };
  return (
    <div className="privacy-policy">
      <Helmet>
        <title>Movieloaded - Privacy Policy</title>
      </Helmet>
      <div className="back">
        <MdArrowBack onClick={navigateToHomePage} />
      </div>
      <br />
      <br />
      <h2>Privacy Policy</h2>
      <p>At Movieloaded, we take privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website.</p>
      <h3>Information We Collect</h3>
      <p>We collect personal information that you provide to us when you create an account, make a purchase, or contact us for support. This information may include your name, email address, billing information, and other details that you choose to provide.</p>
      <h3>How We Use Your Information</h3>
      <p>We use your personal information to provide you with the services you request, such as processing your orders and providing customer support. We may also use your information to send you promotional emails or other marketing materials, but you can opt out of these communications at any time.</p>
      <h3>How We Protect Your Information</h3>
      <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. We use industry-standard encryption technologies to secure your data and limit access to only those employees who need it to perform their job duties.</p>
      <h3>Third-Party Services</h3>
      <p>We may use third-party services, such as payment processors or analytics providers, to help us operate our website. These services may have access to your personal information, but we only share the information necessary to perform their services and require them to maintain the confidentiality of your data.</p>
      <h3>Cookies</h3>
      <p>We use cookies to improve your experience on our website and to personalize your interactions with us. You can choose to disable cookies in your browser settings, but this may limit your ability to use certain features of our website.</p>
      <h3>Changes to This Policy</h3>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website.</p>
      <h3>Contact Us</h3>
      <p> <a href='/contact'>Contact Us</a> </p>
    </div>
  );
};

export default PrivacyPolicy;
