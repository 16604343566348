import React from "react";

import { MdOutlineMovieFilter } from "react-icons/md";
import { FaInfoCircle, FaPhone, FaLock } from "react-icons/fa";
// import { RiContactsBook2Fill } from "react-icons/ri";
import { useContext } from "react";
import MovieContext from "../MovieContext";

const Panel = () => {
  const {
    header,
    fetchPopular,
  } = useContext(MovieContext);

  return (
    <div className="panel">
      <div>
        <MdOutlineMovieFilter
          onClick={() => fetchPopular()}
          className={header === "Trending" ? "active" : null}
        />

        <div>
          <a href="/about">
          <FaInfoCircle>

          </FaInfoCircle>
          </a>
        </div>
        <div>
          <a href="/contact">
          <FaPhone>

          </FaPhone>
          </a>
        </div>
        <div>
          <a href="/privacy">
          <FaLock>

          </FaLock>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Panel;
