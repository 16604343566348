import "./App.css";
import { Routes, Route } from "react-router-dom";
import { MovieProvider } from "./MovieContext";
import Panel from "./components/Panel";
import Container from "./components/Container";
import Details from "./components/Details";
import About from "./components/About";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import CategoryList from "./components/MovieCategory";

function App() {
  return (
    <div className="App">
      <MovieProvider>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Panel />
                <Container />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <About />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Contact />
              </>
            }
          />
          <Route
            path="/privacy"
            element={
              <>
                <Privacy />
              </>
            }
          />
          <Route path="/movie">
            <Route path=":movieSlug" element={<Details />} />
          </Route>
          <Route path="/movie/category">
            <Route path=":categorySlug" element={<CategoryList />} />
          </Route>
        </Routes>
      </MovieProvider>
    </div>
  );
}

export default App;
