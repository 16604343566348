import React from "react";
import { Link } from "react-router-dom";
// import { useContext } from "react";
// import MovieContext from "../MovieContext";
import { motion } from "framer-motion";
import defaultImage from "../assets/no-image.jpg";

function Movie({ movie }) {
  // const { addToFavourites, isFav } = useContext(MovieContext);

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      layout
      className="movie"
    >
      <Link to={`/movie/${movie.slug}`} key={movie.id}>
        <div className="shadow"></div>
      </Link>
      {movie.poster_path !== null ? (
        <img alt={movie.title} src={movie.image} />
      ) : (
        <img loading="lazy" alt={movie.title} src={defaultImage} />
      )}
      <Link to={`/movie/${movie.slug}`} key={movie.id}>
        <h2 style={{ color: "#fff", textAlign: "center" }}>
          DOWNLOAD MOVIE: {movie.title} [{movie.category}]<br />
          <br /> <span>{movie.category}</span>{" "}
          <i
            className="fa fa-arrows-alt-h"
            style={{ fontSize: "15px", color: "#fff" }}
            aria-hidden="true"
          ></i>{" "}
          <span>{movie.uploaded_date}</span>{" "}
          <p style={{ fontSize: "12px", color: "#fff" }}>
            Download {`${movie.title}`}
            <span> </span>
            {`${movie.category}`} Movie Mp4 Film
            <span> </span>
            {`${movie.full_summary.substring(0, 30)}...`}
          </p>
        </h2>
      </Link>
    </motion.div>
  );
}

export default Movie;
