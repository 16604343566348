import React, { useState, useEffect, useCallback } from "react";
import { MdArrowBack } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import defaultImage from "../assets/no-image.jpg";

const Details = () => {
  let params = useParams();
  let navigate = useNavigate();

  const [movie, setMovie] = useState();
  const [moviesInCategory, setMoviesInCategory] = useState([]);

  const fetchMoviesInCategory = useCallback(async (category) => {
    const data = await fetch(
      `https://www.netnaija.one/movieapi/v1/?category=${category}`
    );
    const movies = await data.json();
    setMoviesInCategory(movies.results.slice(0, 6));
  }, []);

  const fetchMovie = useCallback(
    async (slug) => {
      const data = await fetch(`https://www.netnaija.one/movieapi/v1/${slug}/`);
      const movie = await data.json();
      setMovie(movie);
      fetchMoviesInCategory(movie.category);
    },
    [fetchMoviesInCategory]
  );

  useEffect(() => {
    fetchMovie(params.movieSlug);
  }, [params.movieSlug, fetchMovie]);

  
  const handleFastClick = () => {
    window.open("https://www.highrevenuenetwork.com/qbcgerhky?key=008f62397c5560895855c50cfc8aa86e", "_blank");
  };

  const handleDownloadClick = () => {
    window.open(movie.download_link, "_blank");
  };

  const handleSubtitleClick = () => {
    window.open(movie.sub_link, "_blank");
  };

  const navigateToHomePage = () => {
    navigate("/");
  };

  if (!movie) {
    return null; // Render null or a loading spinner while movie data is being fetched
  }
  return (
    <div>
      <Helmet>
        <title>DOWNLOAD MOVIE: {movie.title} MP4 Download - Netnaija</title>
        <meta name="description" content={`DOWNLOAD MOVIE: ${movie.title} MP4 Download. ${movie.title} has been released and trending in the movie industry`}
        />
        <link
          rel="canonical"
          href={`https://movieloaded.xyz/movie/${movie.slug}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${movie.title} - Movieloaded`} />
        <meta
          property="og:description"
          content={`DOWNLOAD MOVIE: ${movie.title} MP4 Download. ${movie.title} has been released and trending in the movie industry`}
        />
        <meta
          property="og:url"
          content={`https://movieloaded.xyz/movie/${movie.slug}`}
        />
        <meta property="og:site_name" content="Movieloaded" />
        <meta property="article:published_time" content={movie.created} />
        <meta property="og:image" content={movie.image} />
        <meta property="og:image:width" content="550" />
        <meta property="og:image:height" content="309" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="author" content="Kez" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@Movieloaded" />
        <meta name="twitter:site" content="@Movieloaded" />
        <meta name="twitter:image" content={movie.image} />
        {/* <script type='text/javascript' src='//crumbrationally.com/5f/85/35/5f8535fdc91d1c8e8a1f099f70d340c1.js'></script> */}
      </Helmet>

      <div className="back">
        <MdArrowBack onClick={navigateToHomePage} />
      </div>
      {movie && (
        <div className="container details">
          <h1 className="section-title">
            DOWNLOAD MOVIE: {movie.title} [{movie.category}]
          </h1>
          {movie.image !== null ? (
            <img alt="{movie.title}" className="img-bg" src={movie.image} />
          ) : (
            <img
              alt="Default {movie.title}"
              className="img-bg"
              src={defaultImage}
            />
          )}
          <div>
            <h2>About {movie.title} mp4 Download </h2>
            <p>
              <div dangerouslySetInnerHTML={{ __html: movie.summary }} /> <br />
              <div dangerouslySetInnerHTML={{ __html: movie.full_summary }} />
            </p>
          </div>
          <div>
            {movie.youtube.startsWith("https") && (
              <div>
                <h2>DOWNLOAD {movie.title} Trailer</h2>
                <iframe
                  title="{movie.title} Trailer"
                  src={movie.youtube}
                  width="100%"
                  height="350px"
                  style={{
                    width: "100%", // Set initial width to 100%
                    maxWidth: "560px", // Set maximum width for desktop screens
                    margin: "0 auto", // Center the iframe horizontally
                  }}
                ></iframe>
              </div>
            )}
          </div>
          <div>
            <h2 style={{ paddingTop: "12px" }}>
              {" "}
              DOWNLOAD {movie.title} Abstract
            </h2>
            <div className="text-div">
              <p>
                <div dangerouslySetInnerHTML={{ __html: movie.abstract }} />
              </p>
            </div>
          </div>
          <div>
            <h2>Uploaded Date</h2>
            <p>{movie.uploaded_date}</p>
          </div>
          <div>
            <h2>Download Links</h2>
            <div className="button-container">
              <button onClick={handleFastClick} id="download-button">
                Fast Download <FiExternalLink />
              </button>
              
            </div>
            <br/>
            <div className="button-container">
              <button onClick={handleDownloadClick} id="download-button">
                Download <FiExternalLink />
              </button>
              <button onClick={handleSubtitleClick} id="subtitle-button">
                Subtitles <FiExternalLink />
              </button>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h2>Social Sharing</h2>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                window.location.href
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "10px" }}
            >
              <i className="fab fa-facebook-f" style={{ fontSize: "24px" }}></i>
            </a>
            <a
              href={`https://twitter.com/share?url=${encodeURIComponent(
                window.location.href
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "10px" }}
            >
              <i className="fab fa-twitter" style={{ fontSize: "24px" }}></i>
            </a>
            <a
              href={`https://telegram.me/share/url?url=${encodeURIComponent(
                window.location.href
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "10px" }}
            >
              <i className="fab fa-telegram" style={{ fontSize: "24px" }}></i>
            </a>
            <a
              href={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
                window.location.href
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "10px" }}
            >
              <i className="fab fa-pinterest" style={{ fontSize: "24px" }}></i>
            </a>
            <a
              href={`https://wa.me/?text=${encodeURIComponent(
                window.location.href
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "10px" }}
            >
              <i className="fab fa-whatsapp" style={{ fontSize: "24px" }}></i>
            </a>
          </div>
          <div>
            <h2>Movie Category</h2>
            <p>
              <a href={`/movie/category/${movie.category}`}>
                {movie.category.toUpperCase()} <FiExternalLink />
              </a>
            </p>
          </div>

          <div>
            <h2>Other {movie.category} Movies</h2>
            <ul>
              {moviesInCategory.map((movie) => {
                const createdDate = new Date(movie.created);

                return (
                  <li key={movie.id} style={{ marginBottom: "20px" }}>
                    <a
                      href={`/movie/${movie.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={movie.image}
                          alt={movie.title}
                          style={{ width: "100px", marginRight: "10px" }}
                        />
                        <div>
                          <h4>DOWNLOAD MOVIE: {movie.title}</h4>
                          <p>{createdDate.toLocaleDateString()}</p>
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Details);
