import React, { useContext, useEffect, useState } from "react";
import Movie from "./Movie";
import MovieContext from "../MovieContext";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const fetchMovies = async (page, setFiltered) => {
  try {
    const apiUrl = `https://www.netnaija.one/movieapi/v1/?page=${page}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    setFiltered(data.results);
  } catch (error) {
    console.error("Error fetching movies:", error);
  }
};

const MoviesList = () => {
  const { filtered, setFiltered } = useContext(MovieContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchMovies(currentPage, setFiltered);
  }, [currentPage, setFiltered]);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <Helmet>
        <title>Download Latest Movies 2023 Netnaija, Netnaija Movies</title>
        <meta
          name="description"
          content="Download Latest Nollywood, Hollywood & Bollywood Movies on movieloaded.xyz, Movieloaded movies download, Movieloaded action movies download 2023."
        />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://www.movieloaded.xyz" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Download Latest Movies 2023 on movieloaded.xyz"
        />
        <meta
          property="og:description"
          content="Download Latest Nollywood, Hollywood & Bollywood Movies on movieloaded.xyz, Movieloaded movies download, Movieloaded action movies download 2023."
        />
        <meta property="og:url" content="https://www.movieloaded.xyz" />
        <meta property="og:site_name" content="movieloaded.xyz" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/Movieloaded"
        />
        <meta property="og:image" content="%PUBLIC_URL%/movieloaded.xyz.jpg" />
        <meta
          property="og:image:secure_url"
          content="%PUBLIC_URL%/movieloaded.xyz.jpg"
        />
        <meta property="og:image:width" content="820" />
        <meta property="og:image:height" content="360" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Download Latest Movies 2023 on movieloaded.xyz"
        />
        <meta
          name="twitter:description"
          content="Download Latest Nollywood, Hollywood & Bollywood Movies on movieloaded.xyz, Movieloaded movies download, Movieloaded action movies download 2023."
        />
        <meta name="twitter:site" content="@Movieloaded_icu" />
        <meta name="twitter:image" content="%PUBLIC_URL%/movieloaded.xyz.jpg" />
        <meta name="twitter:label1" content="Posts" />
        <meta name="twitter:data1" content="6517" />
      </Helmet>
      <motion.div layout className="popular-movies">
        {filtered.map((movie) => (
          <Movie key={movie.id} movie={movie} />
        ))}
      </motion.div>
      {filtered.length === 0 && <p className="info">No movies to found</p>}
      {filtered.length > 11 && (
        <div className="pagination">
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage}</span>
          <button onClick={goToNextPage}>Next</button>
        </div>
      )}
    </>
  );
};

export default React.memo(MoviesList);
