import React from "react";
import { Helmet } from "react-helmet";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const About = () => {
  let navigate = useNavigate();
  const navigateToHomePage = () => {
    navigate("/");
  };
  return (
    <div className="about">
      <Helmet>
        <title>Contact Us - Download Movies Online | Netnaija Movies</title>
        <meta
          name="description"
          content="For sponsorship/inquiries, take down and general information,"
        />
      </Helmet>
      <div className="back">
        <MdArrowBack onClick={navigateToHomePage} />
      </div>
      <br />
      <br />
      <h2>Contact Us</h2>
      <p>
        For sponsorship/inquiries, take down and general information, please
        send us an email: info@movieloaded.xyz and
        our team will respond in less than 1 – 10 mins.
      </p>
      <h3>Privacy Policy</h3>
      <a href="/privacy">Privacy Policy</a>
    </div>
  );
};

export default About;
